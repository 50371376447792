<template>
  <div class="scheme-contain">
    <div class="search-contain">
      <Row>
        <Col :lg="{ span: 8, offset: 8 }" :md="{ span: 12 }">
          <Date-picker
            v-model="dateRange"
            type="daterange"
            placeholder="选择日期"
            @on-change="changeTime"
          ></Date-picker>
        </Col>
      </Row>
    </div>
    <div class="CheckPrint-contain">
      <Row>
        <Col :lg="{ span: 20 }" :md="{ span: 24 }">
          <Radio-group
            v-model="radioValue"
            :value="radioValue"
            @on-change="changeRadio"
          >
            <Radio label="全部"></Radio>
            <Radio
              v-for="picType in picTypeList"
              :label="picType.name"
              :key="picType.name"
            ></Radio>
          </Radio-group>
        </Col>
        <Col :lg="{ span: 4 }" :md="{ span: 24 }">
          <div
            class="member-oprate"
            v-show="power.indexOf('会员管理--编辑会员信息权限') > -1"
          >
            <router-link class="new-doc fr" :to="{ name: 'CheckPrintAdd' }"
              >添加图片</router-link
            >
          </div>
        </Col>
      </Row>
      <div class="inspect-contain">
        <div class="inspect-box">
          <Row>
            <Col
              :lg="{ span: 4 }"
              :md="{ span: 8 }"
              class="tac"
              v-for="picData in picList"
              :key="picData.id"
            >
              <div class="records-module">
                <div
                  class="records-img"
                  @click="handleDetailRouter(picData.id)"
                >
                  <img
                    :src="picData.photo"
                    class="img-body"
                    v-if="isImage(picData.photo)"
                  />
                  <img
                    src="@/assets/img/placeholder.png"
                    class="img-body"
                    v-else
                  />
                </div>
                <div class="records-text">
                  <p>{{ picData.name }}</p>
                  <p class="records-date">{{ picData.create_time }}</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col :lg="{ span: 24 }" :md="{ span: 24 }"
          ><span class="records">共{{ totalSize }}条记录</span></Col
        >
      </Row>
    </div>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page
          :total="totalSize"
          :current="curPage"
          :page-size="sizePerPage"
          @on-change="changePage"
        ></Page>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			radioValue: '全部',
			type_id: 0,
			type_name: '',
			curPage: 1,
			totalSize: 0,
			sizePerPage: 12,
			dateRange: [],
			picList: [],
			picTypeList: [],
			power: [],
		};
	},

	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},

	created() {
		this.power = this.userInfo.power;
		var reqData = {
			member_id: this.memberId,
			type_name: this.type_name,
			page: 1,
			size: 12,
		};
		MemberService.listPicType().then((data) => {
			this.picTypeList = data;
		});

		MemberService.queryPicCheck(reqData).then((data) => {
			this.picList = data.list;
			this.totalSize = data.row_size;
			this.curPage = data.cur_page;
		});
	},

	methods: {
		searchPic() {
			var reqData = {
				member_id: this.memberId,
				type_name: this.type_name,
				start: this.dateRange[0],
				end: this.dateRange[1],
				page: this.curPage,
				size: this.sizePerPage,
			};

			MemberService.queryPicCheck(reqData).then((data) => {
				this.picList = data.list;
				this.totalSize = data.row_size;
				this.curPage = data.cur_page;
			});
		},

		changeTime(date) {
			this.dateRange = date;
			this.searchPic();
		},

		changeRadio(data) {
			if (data === '全部') {
				this.type_name = '';
			} else {
				this.type_name = data;
			}
			this.searchPic();
		},

		changePage(page) {
			this.curPage = page;
			this.searchPic();
		},

		handleDetailRouter(index) {
			this.$router.push({ name: 'CheckPrintDetail', params: { pic_id: index } });
		},
		isImage(path) {
			return path.match(/\.(png|jpe?g|gif|svg)$/);
		},
	},
};
</script>
<style lang="css" scoped>
.CheckPrint-contain {
  padding: 0 30px;
  margin-top: 40px;
}
.CheckPrint-contain .ivu-radio-wrapper {
  margin-right: 30px;
}
.CheckPrint-contain .inspect-contain {
  margin-top: 30px;
}
.records-module {
  margin-bottom: 20px;
  display: inline-block;
}
.records-img {
  width: 195px;
  height: 220px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}
.records-date {
  color: #999;
}
.records-text {
  width: 195px;
}
.img-body {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ivu-row {
  display: flex;
  flex-wrap: wrap;
}
</style>
